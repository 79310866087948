<template>
  <div>
    <b-overlay
        :show="!pageLoaded"
    >
  <b-card
      class="owner-create-wrapper"
  >
    <b-row>
        <b-col md="10">
          <p><b>PRACTICE BENEFICAL OWNER/MANAGER PROFILE:</b> This is where details of a Beneficial Owner, Officer or Manager are provided.</p>
        </b-col>
        <b-col md="2" class="text-right">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="showHelp(true)"
          >
            <span>Help</span>
          </b-button>
        </b-col>
    </b-row>

    <!--form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <h3>General Information</h3>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="First Name"
              label-for="owner-first-name"
              class="mb-2"
          >
            <b-form-input
                id="owner-first-name"
                v-model="owner.firstName"
                @change="saveOwnerProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Last Name"
              label-for="owner-last-name"
              class="mb-2"
          >
            <b-form-input
                id="owner-last-name"
                v-model="owner.lastName"
                @change="saveOwnerProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
            <b-form-group label="Boom #" label-for="boom-number" class="mb-2">
                <b-form-input id="boom-number" v-model="owner.boomNumber"/>
            </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Unique email address for this individual"
              label-for="owner-email"
              class="mb-2"
          >
            <b-form-input
                id="owner-email"
                v-model="owner.email"
                @change="saveOwnerProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Title"
              label-for="owner-title"
              class="mb-2"
          >
            <b-form-input
                id="owner-title"
                v-model="owner.title"
                @change="saveOwnerProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <strong>Select all the relevant attributes for this individual from the list below</strong>
          <b-form-group>
            <b-form-checkbox
                v-model="owner.attributes"
                id="sole-practitioner"
                name="owner_attributes"
                value="Sole practitioner"
                @change="saveOwnerProgress"
            >
              Sole practitioner
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.attributes"
                id="responsible-for-compliance"
                name="owner_attributes"
                value="Responsible for MLR compliance/ nominated officer"
                @change="saveOwnerProgress"
            >
              Responsible for MLR compliance/ nominated officer
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.attributes"
                id="controls-practice"
                name="owner_attributes"
                value="Owns or exercises ultimate control of the practice"
                @change="saveOwnerProgress"
            >
              Owns or exercises ultimate control of the practice
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.attributes"
                id="company-director"
                name="owner_attributes"
                value="Company Director or Secretary"
                @change="saveOwnerProgress"
            >
              Company Director or Secretary
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.attributes"
                id="holds-voting-rights"
                name="owner_attributes"
                value="Holds >25% shares or voting rights/capital or profits or voting rights"
                @change="saveOwnerProgress"
            >
              Holds >25% shares or voting rights/capital or profits or voting rights
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.attributes"
                id="partner-member"
                name="owner_attributes"
                value="Partner member of LLP"
                @change="saveOwnerProgress"
            >
              Partner member of LLP
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Is this person a shareholder?"
              label-for="owner-shareholder"
              class="mb-2"
          >
            <v-select
                id="owner-active"
                v-model="owner.shareholder"
                :options="yesNo"
                @input="saveOwnerProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Is this individual active in public practice?"
              label-for="owner-active"
              class="mb-2"
          >
            <v-select
                id="owner-active"
                v-model="owner.activeInPublicPractice"
                :options="yesNo"
                @input="saveOwnerProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="owner.shareholder === 'Yes'">
          <b-form-group
              label="What is the % holding of this Individual?"
              label-for="owner-holding-percent"
              class="mb-2"
          >
            <cleave
                id="owner-holding-percent"
                v-model="owner.holdingPercent"
                @blur="saveOwnerProgress"
                class="form-control"
                :raw="false"
                :options="options.number"
                placeholder="100"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <strong>Select all the professional memberships held by this individual from the list below</strong>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-form-checkbox
                v-model="owner.memberships"
                id="iab"
                name="owner_memberships"
                value="IAB"
                @change="saveOwnerProgress"
            >
              IAB
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.memberships"
                id="hmrc"
                name="owner_memberships"
                value="HMRC"
                @change="saveOwnerProgress"
            >
              HMRC
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.memberships"
                id="aat"
                name="owner_memberships"
                value="AAT"
                @change="saveOwnerProgress"
            >
              AAT
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.memberships"
                id="acca"
                name="owner_memberships"
                value="ACCA"
                @change="saveOwnerProgress"
            >
              ACCA
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.memberships"
                id="aia"
                name="owner_memberships"
                value="AIA"
                @change="saveOwnerProgress"
            >
              AIA
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.memberships"
                id="att"
                name="owner_memberships"
                value="ATT"
                @change="saveOwnerProgress"
            >
              ATT
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.memberships"
                id="cima"
                name="owner_memberships"
                value="CIMA"
                @change="saveOwnerProgress"
            >
              CIMA
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-form-checkbox
                v-model="owner.memberships"
                id="ciot"
                name="owner_memberships"
                value="CIOT"
                @change="saveOwnerProgress"
            >
              CIOT
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.memberships"
                id="icb"
                name="owner_memberships"
                value="ICB"
                @change="saveOwnerProgress"
            >
              ICB
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.memberships"
                id="icaew"
                name="owner_memberships"
                value="ICAEW"
                @change="saveOwnerProgress"
            >
              ICAEW
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.memberships"
                id="cai"
                name="owner_memberships"
                value="CAI"
                @change="saveOwnerProgress"
            >
              CAI
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.memberships"
                id="icas"
                name="owner_memberships"
                value="ICAS"
                @change="saveOwnerProgress"
            >
              ICAS
            </b-form-checkbox>
            <b-form-checkbox
                v-model="owner.memberships"
                id="ifa"
                name="owner_memberships"
                value="IFA"
                @change="saveOwnerProgress"
            >
              IFA
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="userData && userData.role == 'admin'">
          <b-form-group
              label="Is this BO approved by the IAB compliance team?"
              label-for="bo-approved"
              class="mb-2"
          >
            <v-select
                id="bo-approved"
                v-model="owner.complianceApprovedBO"
                :options="yesNo"
                @input="saveOwnerProgress"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <risk-question-block :system-form-id="5" :form="form" :answers="owner.answers"
                           v-on:updateProgressReturn="updateProgressListener"
                           v-on:updateRiskReturn="updateRiskListener" v-on:getAnswersReturn="getAnswersListener"
                           v-on:updateFilesReturn="updateFilesListener"></risk-question-block>

      <b-row>
        <b-col
            cols="8"
            class="mt-50"
        >
          <b-button
              :disabled="savingOwner"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="saveOwner(false)"
          >
            <span v-if="!savingOwner">Save Changes</span>
            <span v-if="savingOwner"><b-spinner small/> Saving...</span>
          </b-button>
          <b-button
              :disabled="!canComplete || completed || savingOwner"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="!completed ? 'success' : 'flat-success'"
              class="mr-1"
              @click="saveOwner(true)"
          >
            <span class="align-middle" v-if="!completed && !savingOwner">Complete</span>
            <span class="align-middle" v-if="completed &&  !savingOwner"><feather-icon
                icon="CheckIcon"/> Completed</span>
            <span v-if="savingOwner"><b-spinner small/> Saving...</span>
          </b-button>
        </b-col>
        <b-col
            cols="4"
            class="mt-50 text-right"
        >
          <sup>Last updated: {{ owner.lastUpdated }}</sup>
        </b-col>
      </b-row>
    </b-form>
    <!--form-->
  </b-card>
    </b-overlay>
  <HelpComponent v-model="shallShowHelpModal" v-show="shallShowHelpModal" @close="closeModal" :content="modalContent"/>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.gb'
import ownerService from "@core/services/owner/useOwner";
import RiskQuestionBlock from "../../blocks/RiskQuestionBlock";
import {$themeConfig} from "@themeConfig";
import HelpComponent from "../../components/HelpComponent.vue";
import userService from "@core/services/user/useUser";

export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BSpinner,
    BOverlay,
    RiskQuestionBlock,
    vSelect,
    Cleave,
    HelpComponent
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if ( this.$route.params.id ){
      this.owner.id = this.$route.params.id;
    }
  },
  data() {
    return {
      userData: {},
      modalContent: `<p>CCAB Guidance (Section 3,25) Defines a BOOM as</p>
<p class='zero-bottom-margin'><b>A sole practitioner:</b></p>
<ul>
   <li>a partner, or LLP member, in a firm who:
    <ul class='circle-bullets'>
       <li> holds (directly or indirectly) more than 25% of the capital, or profits or voting rights; or </li>
       <li> exercises ultimate control; and </li>
    </ul>
   </li>
   <li>
      a shareholder in a limited company who: 
      <ul class='circle-bullets'>
         <li> holds (directly or indirectly) more than 25% of the shares or voting rights; or </li>
         <li> ultimately owns or exercises ultimate control. </li>
      </ul>
   </li>
</ul>
<p class='zero-bottom-margin'><b>Officer:</b></p>
<ul>
   <li>a sole practitioner</li>
   <li>a partner in a partnership (including a Scottish Limited Partnership (SLP)); </li>
   <li>a member in a limited liability partnership (LLP); </li>
   <li>a director or company secretary in a limited company; and a sole practitioner; </li>
   <li>a partner, or LLP member, in a firm who: 
      <ul class='circle-bullets'>
        <li>holds (directly or indirectly) more than 25% of the capital, or profits or voting rights; or </li>
        <li>exercises ultimate control; and </li>
      </ul>
   </li>
   <li>a shareholder in a limited company who: 
      <ul class='circle-bullets'>
        <li>holds (directly or indirectly) more than 25% of the shares or voting rights; or </li>
        <li>ultimately owns or exercises ultimate control. </li>
      </ul>
   </li>
</ul>
<p class='zero-bottom-margin'><b>Manager:</b></p>
<ul>
   <li>the nominated officer (the MLRO); </li>
   <li>the member of the board of directors (or if there is no board, of its equivalent management body) or of its senior management as the officer responsible for the firm’s compliance with MLR17; 
and
any other principal, senior manager, or member of a management committee who is responsible for setting, approving or ensuring the firm’s compliance with the firm’s Anti-Money Laundering policies and procedures, in relation to the following areas: 
    <ul class='circle-bullets'>
        <li>client acceptance procedures;</li>
        <li>the firm’s risk management practices; </li>
        <li>internal controls, including employee screening and training for AML purposes; </li>
        <li>internal audit or the annual AML compliance review process; </li>
        <li>customer due diligence, including policies for reliance; and </li>
        <li>AML record keeping.</li>
      </ul>
    </li>
<ul>
`,
      shallShowHelpModal : false,
      owner: {
        id: 0,
        practiceId: 0,
        firstName: "",
        lastName: "",
        email: "",
        title: "",
        attributes: [],
        memberships: [],
        activeInPublicPractice: "",
        shareholder: "",
        holdingPercent: 0,
        boomNumber: "",
        lastUpdated: "",
        complianceApprovedBO: "",
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      form: {},
      riskQuestions: [],
      structureTypes: ['Sole Trader', 'Partnership', 'Ltd Company', 'Limited Partnership', 'Limited Liability Partnership', 'Other'],
      yesNoUncertain10: [{label: 'Yes', risk: 0}, {label: 'No', risk: 10}, {label: 'Uncertain', risk: 10}],
      yesNoUncertain5: [{label: 'Yes', risk: 0}, {label: 'No', risk: 5}, {label: 'Uncertain', risk: 5}],
      yesNoUncertain: ['Yes', 'No', 'Uncertain'],
      yesNo: ['Yes', 'No'],
      services: ['Accounts Preparation', 'Corporation Tax', 'Budgets', 'CIS (Construction Industry Scheme)', 'Cash Flow Forecast', 'Management Accounts', 'Payroll and Auto Enrolment Services', 'Income Tax Self-Assessment', 'Tax Advice / Planning', 'VAT Submission'],
      ownerRisk: 0,
      canComplete: false,
      completed: false,
      savingOwner: false,
      pageLoaded: false
    }
  },
  methods: {
    showHelp() {
        this.shallShowHelpModal = true;
    },
    closeModal() {
        this.shallShowHelpModal = false;
    },
    getAnswersListener(value) {
      this.riskQuestions = value;
      this.getOwner();
    },
    getOwner() {
      if (this.owner.id) {
        ownerService.getOwner({
          id: this.owner.id
        }).then(response => {
          this.owner = response.data;
          this.canComplete = this.owner.isComplete;
          this.completed = this.owner.completed;
          this.ownerRisk = this.owner.risk;
          this.pageLoaded = true;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }else{
        this.pageLoaded = true;
      }
    },
    saveOwnerProgress() {
      this.owner.practiceId = this.userData.practiceId;
      this.owner.holdingPercent = parseInt(this.owner.holdingPercent);
      if (this.owner.holdingPercent > 100) {
        this.owner.holdingPercent = 100;
      }
      if (this.owner.holdingPercent < 0) {
        this.owner.holdingPercent = 0;
      }
      if (this.owner.id) {
        ownerService.updateOwner({
          owner: this.owner,
          complete: false,
          id: this.owner.id
        }).then(response => {
          this.canComplete = response.data.complete;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      } else {
        ownerService.createOwner({
          owner: this.owner,
          complete: false,
        }).then(response => {
          if (response.data.id) {
            this.owner.id = response.data.id;
          }
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }
    },
    saveOwner(complete) {
      this.owner.practiceId = this.userData.practiceId;

      this.savingOwner = true;
      if (this.owner.id) {
        ownerService.updateOwner({
          owner: this.owner,
          complete: complete,
          id: this.owner.id
        }).then(response => {
          if ( response.data.errors ){
            this.showErrorMessage(response.data.errors);
          }else{
            this.showSuccessMessage("You have successfully updated this owner");
            window.location.href = '/owner/' + this.owner.id;
            if ( complete && !this.completed ){
              this.completed = true;
            }
          }
          this.savingOwner = false;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      } else {
        ownerService.createOwner({
          owner: this.owner,
          complete: complete,
        }).then(response => {
          if ( response.data.errors ){
            this.showErrorMessage("Something went wrong!");
          }else{
            this.showSuccessMessage("You have successfully created a new owner");
            this.owner.id = response.data.id;
            window.location.href = '/owner/' + this.owner.id;
          }
          this.savingOwner = false;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }
    },
    updateProgressListener(value) {
      this.owner.answers = value;
      this.saveOwnerProgress();
    },
    updateFilesListener(value) {
      this.files = value;
    },
    updateRiskListener(value) {
      this.ownerRisk = value;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
